import { render, staticRenderFns } from "./pregnant_tips.vue?vue&type=template&id=480d0a88&scoped=true&"
import script from "./pregnant_tips.vue?vue&type=script&lang=js&"
export * from "./pregnant_tips.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480d0a88",
  null
  
)

export default component.exports